<template>
    <div class="container">
        <div class="heading">
            <h1 class="gradient-text">{{$t('Meet Our Team')}}</h1>
            <p>
                {{$t('Meet Our Team p')}}
            </p>
        </div>

        <div class="wrapper">
            <div class="grid">
                <div class="item">
                    <div class="photo"><img src="@/assets/geva.png" alt="profile"></div>
                    <div class="content">
                        <h1 class="gradient-text">Gevorg Bayatyan</h1>
                        <h2>{{$t('Front End Design')}}</h2>
                    </div>
                </div>
                <div class="item">
                    <div class="photo"><img src="@/assets/shepel.jpg" alt="profile"></div>
                    <div class="content">
                        <h1 class="gradient-text">{{$t('Oleksandr')}}</h1>
                        <h2>{{$t('CEO')}}</h2>
                    </div>
                </div>
                <div class="item">
                    <div class="photo"><img src="@/assets/sergio.jpg" alt="profile"></div>
                    <div class="content">
                        <h1 class="gradient-text">{{$t('Sergei')}}</h1>
                        <h2>{{$t('CBDO')}}</h2>
                    </div>
                </div>
                <div class="item">
                    <div class="photo"><img src="@/assets/den.jpg" alt="profile"></div>
                    <div class="content">
                        <h1 class="gradient-text">{{$t('Denys')}}</h1>
                        <h2>{{$t('CTO')}}</h2>
                    </div>
                </div>
                <div class="item">
                    <div class="photo"><img src="@/assets/viktoria.jpg" alt="profile"></div>
                    <div class="content">
                        <h1 class="gradient-text">Victoria Evstegneeva</h1>
                        <h2>{{$t('Community Manager')}}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
}
</script>

<style scoped>
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 5rem 0 5rem;
}
.heading {
}

.heading h1 {
    font-size: 2rem;
    letter-spacing: 3px;
    font-weight: 600;
}

.heading p {
    letter-spacing: 3px;
    word-spacing: 3px;
    font-weight: 300;
    font-size: 1.4rem;
    width: 80%;
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
}

.wrapper {
    background-color: #0b2229;
    color: var(--bg);
    height: min-content;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 3rem;
    border-radius: 2rem;
}

.wrapper .grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.wrapper .grid .photo {
    width: 120px;
    height: 120px;
    margin: 1rem auto;
}

.wrapper .grid .photo img {
    border-radius: 50%;
    border: 2px solid #41f6f6;
}

.wrapper .grid .item {
    text-align: center;
    user-select: none;
    transition: all 0.15s;
}

.content {
    border-radius: 1rem;
    width: 140px;
    margin: auto;
}

.wrapper h1 {
    font-size: 1.4rem;
    font-weight: 600;
}

.wrapper h2 {
    font-weight: 300;
    font-size: 1rem;
    margin-top: 1rem;
}

@media (max-width: 1024px) {
    .wrapper .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 2;
        gap: 2em;
        column-gap: 0;
    }

    .wrapper .grid .photo {
        width: 140px;
        height: 140px;
        margin: 1rem auto;
    }

    .content {
        width: 140px;
    }
}

@media (max-width: 800px) {
    .wrapper .grid {
        display: flex;
        flex-direction: column;
    }

    .wrapper .grid .photo {
        width: 180px;
        height: 180px;
    }

    .content {
        width: 180px;
    }
    .wrapper h1 {
        font-size: 2rem;
    }

    .wrapper h2 {
        font-size: 1.2rem;
    }
}

@media (max-width: 600px) {
    .wrapper .grid .photo {
        width: 80%;
        height: 40%;
    }

    .content {
        width: 80%;

    }
    .wrapper h1 {
        font-size: 1.2rem;
    }

    .wrapper h2 {
        font-size: 1rem;
    }
}

@media (max-width: 360px) {}
</style>