import en from './en.json';
import ru from './ru.json';

const defaultLocale = 'en';

export { defaultLocale };

export const languages = {
    en,
    ru
};