<template>
  <nav>
    <navView />
  </nav>

  <header class="container" id="home">
    <headerView />
  </header>

  <section ref="scenario-analysis" id="features">
    <scenarioAnalysisView />
  </section>

  <section class="about" id="about">
    <aboutUs />
  </section>

  <section id="roadmap">
    <RoadMapView />
  </section>

  <section id="team">
    <teamSectionView />
  </section>

</template>

<script>
import headerView from '@/components/headerView.vue';
import scenarioAnalysisView from '@/components/scenarioAnalysisView.vue';
import aboutUs from '@/components/aboutUs.vue';
import RoadMapView from '@/components/RoadMapView.vue';
import teamSectionView from '@/components/teamSectionView.vue';

export default {
  components: {
    headerView,
    scenarioAnalysisView,
    teamSectionView,
    RoadMapView,
    aboutUs,
  }
};
</script>

<style lang="css"></style>